<template>
    <div class="back-header dosiin_back-header-sticky back-header-login">
        <button class="go-back-icon" @click="goBack" v-if="router.options.history.state.back">
            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.64548 1.44659L2.08637 8.94025L9.7488 16.5363C9.91585 16.7019 10 16.9098 10 17.1431C10 17.3764 9.91585 17.5843 9.7488 17.7499C9.66852 17.8295 9.57604 17.8931 9.47129 17.9363C9.36645 17.9797 9.25552 18 9.14155 18C9.03388 18 8.92859 17.9777 8.82746 17.9391C8.71641 17.8968 8.61815 17.833 8.5343 17.7499L0.2512 9.53848C0.086077 9.37479 0 9.17058 0 8.94025C0 8.70992 0.086077 8.50571 0.2512 8.34202L8.41376 0.250128C8.58094 0.0843939 8.79189 0 9.02962 0C9.26734 0 9.4783 0.0843938 9.64548 0.250128C9.8106 0.413822 9.89668 0.618024 9.89668 0.848359C9.89668 1.07869 9.8106 1.28289 9.64548 1.44659Z" fill="#484848"></path>
            </svg>
            <div class="text dosiin_ml-2" href="#">Quay lại</div>
        </button>

        <a href="/" class="go-back-icon" v-else>
            <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.64548 1.44659L2.08637 8.94025L9.7488 16.5363C9.91585 16.7019 10 16.9098 10 17.1431C10 17.3764 9.91585 17.5843 9.7488 17.7499C9.66852 17.8295 9.57604 17.8931 9.47129 17.9363C9.36645 17.9797 9.25552 18 9.14155 18C9.03388 18 8.92859 17.9777 8.82746 17.9391C8.71641 17.8968 8.61815 17.833 8.5343 17.7499L0.2512 9.53848C0.086077 9.37479 0 9.17058 0 8.94025C0 8.70992 0.086077 8.50571 0.2512 8.34202L8.41376 0.250128C8.58094 0.0843939 8.79189 0 9.02962 0C9.26734 0 9.4783 0.0843938 9.64548 0.250128C9.8106 0.413822 9.89668 0.618024 9.89668 0.848359C9.89668 1.07869 9.8106 1.28289 9.64548 1.44659Z" fill="#484848"></path>
            </svg>
            <div class="text dosiin_ml-2" href="#">Quay lại</div>
        </a>

        <slot name="center"></slot>
        <div class="back-header-right-side">
            <router-link :to="{ name : 'search'}" class="search-icon dosiin_search-header-btn">
                <i class="dsi dsi-search"></i>
            </router-link>
            <slot name="headerIcon"></slot>
            <a href="/cart" class="cart-icon dosiin_cart-header-btn">
                <i class="dsi dsi-cart"></i>
                <span class="item-badge" v-if="cartAmount > 0">{{ cartAmount }}</span>
            </a>
        </div>
    </div>
</template>

<script>
    import { onMounted , computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    export default {
        setup() {
            const store = useStore();   
            const cartAmount  = computed(() => store.getters['cart/cartTotalItems']);
            const router = useRouter();

            onMounted(()=>{
                const backHeader = document.querySelector('.dosiin_back-header-sticky');
                if (backHeader) {
                    window.addEventListener('scroll' , function(){
                        const { scrollTop  } = document.documentElement;
                        if (scrollTop > backHeader.clientHeight && !backHeader.classList.contains('dosiin_mode-sticky')) {
                            backHeader.classList.add('dosiin_mode-sticky');
                        }else if(scrollTop < backHeader.clientHeight && backHeader.classList.contains('dosiin_mode-sticky')){
                            backHeader.classList.remove('dosiin_mode-sticky');
                        }
                    });
                }
            });

            return {
                cartAmount,
                router
            }
        },
    }
</script>


