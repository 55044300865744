<template>
  <PageMB v-if="layout == 4" />
  <PagePC v-else />
</template>

<script>
import PageMB from "@/pages/mb/Page";
import PagePC from "@/pages/pc/Page";
import pageService from "@/services/page";
import { provide, reactive } from "vue";
import { useRoute } from "vue-router";
import { watch } from "@vue/runtime-core";
export default {
  props: {
    pageParam: {
      type: Object,
      default: {},
    },
  },
  components: {
    PageMB,
    PagePC,
  },
  setup(props) {
    const route = useRoute();
    const state = reactive({
      pageId: props.pageParam.lid,
      page: {},
      relatedPages: [],
    });

    getPage();
    // getRelatedPages();
    async function getPage() {
      const response = await pageService.fetchPage(state.pageId);

      if (response && response.data) {
        state.page = response.data;
      }
    }

    // async function getRelatedPages() {
    //   const response = await pageService.fetchPages({ page_type: "T", status: "H" });

    //   if (response && response.data) {
    //     state.relatedPages = response.data.pages;
    //   }
    // }

    watch(
      () => props.pageParam,
      () => {
        state.pageId = props.pageParam.lid;
        state.page = {};
        getPage();
      }
    );
    provide("getPage", getPage);
    provide("state", state);
  },
};
</script>
