import Api from "@/services/Api";

export default {
  fetchPage(pageId) {
    return Api().get(`/pages/${pageId}`);
  },
  fetchPages(params) {
    return Api().get(`/pages`, params);
  },
};
