<template>
  <main class="page-policy">
    <BackHeader>
      <template v-slot:headerIcon>
        <a
          class="more-menu-wrapper-btn dosiin_more-menu-wrapper action-btn"
          @click="showMenuDropdown"
          style="margin-right: 15px"
        >
          <i class="dsi dsi-more-h"></i>
        </a>
      </template>
    </BackHeader>
    <div class="page-detail-policy" :key="state.pageId">
      <div v-if="state.page.page_id">
        <h1 class="mainbox-title" v-text="state.page.page"></h1>
        <div>
          <div class="mainbox-description" v-html="state.page.description"></div>
        </div>
      </div>
      <LoadingComponent v-else />
    </div>
    <!-- Menu Dropdown -->
    <Teleport to="#modals">
      <div
        class="more-menu-overlay modal"
        id="dosiin_more-menu"
        :class="menuDropdown ? 'open' : ''"
      >
        <div
          class="modal-more-menu"
          id="dosiin_more-menu-container"
          v-click-outside="closeMenuDropdown"
        >
          <DropdownMobile />
        </div>
      </div>
    </Teleport>
  </main>
</template>
<script>
import BackHeader from "@/components/common/BackHeader";
import DropdownMobile from "@/components/menus/DropdownMobile";
import vClickOutside from "click-outside-vue3";
import { ref, inject } from "@vue/runtime-core";
export default {
  components: {
    BackHeader,
    DropdownMobile,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup() {
    const state = inject("state");
    const menuDropdown = ref(false);
    const showMenuDropdown = () => {
      menuDropdown.value = !menuDropdown.value;
    };

    const closeMenuDropdown = () => {
      menuDropdown.value = false;
    };

    return {
      state,
      menuDropdown,
      showMenuDropdown,
      closeMenuDropdown,
    };
  },
};
</script>
