<template>
  <div
    class="dosiin-content-page dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-top"
  >
    <div class="page-section content-page-detail">
      <div class="box-wrapper-pc">
        <div class="box-grid-pc">
          <div class="box-grid-pc_left">
            <div class="menu-slide-bar-wrapper">
              <div class="menu-slide-bar-title">
                <h4>THÔNG TIN CẦN BIẾT</h4>
              </div>
              <div class="menu-slide-bar-list">
                <ul class="menu-content-list">
                  <li
                    class="content-list-item"
                    :class="{
                      active: $route.fullPath == page.route,
                      'sub-content-list-item': page.subpage,
                    }"
                    v-for="(page, i) in pages"
                    :key="i"
                  >
                    <router-link
                      class="dosiin_d-block"
                      v-if="page.route"
                      :to="page.route"
                      v-text="page.title"
                    ></router-link>
                    <a class="dosiin_d-block" v-else v-text="page.title"></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="box-grid-pc_right" :key="state.pageId">
            <div class="content-page-detail-warpper" v-if="state.page.page_id">
              <h1 class="mainbox-content-title section-title">
                {{ state.page.page }}
              </h1>
              <div
                id="news_content"
                class="mainbox-content-body"
                v-html="state.page.description"
              ></div>
            </div>
            <LoadingComponent v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";

export default {
  setup() {
    const state = inject("state");

    const pages = [
      {
        title: "Chính sách và điều khoản",
        route: null,
      },
      {
        title: "Chính sách bảo hành sản phẩm",
        route: "/chinh-sach-bao-hanh-san-pham-l.80",
        subpage: true,
      },
      {
        title: "Chính sách bảo mật",
        route: "/chinh-sach-bao-mat-l.84",
        subpage: true,
      },
      {
        title: "Chính sách bảo mật thông tin thanh toán",
        route: "/chinh-sach-bao-mat-thanh-toan-l.167",
        subpage: true,
      },
      {
        title: "Chính sách dành cho khách hàng",
        route: "/chinh-sach-danh-cho-khach-hang-l.85",
        subpage: true,
      },
      {
        title: "Chính sách thanh toán",
        route: "/chinh-sach-thanh-toan-l.86",
        subpage: true,
      },
      {
        title: "Chính sách đổi trả hàng",
        route: "/chinh-sach-doi-tra-hang-l.78",
        subpage: true,
      },
      {
        title: "Điều khoản dịch vụ",
        route: "/dieu-khoan-dich-vu-l.145",
        subpage: true,
      },
      {
        title: "Các điều khoản khác",
        route: null,
      },
      {
        title: "Chính sách vận chuyển",
        route: "/chinh-sach-van-chuyen-va-giao-nhan-l.79",
        subpage: true,
      },
      {
        title: "Các điều khoản khác",
        route: "/dieu-khoan-and-quy-dinh-l.82",
        subpage: true,
      },
      {
        title: "Hạn chế về thời gian và phạm vi địa lý",
        route: "/cac-han-che-ve-thoi-gian-va-pham-vi-dia-ly-l.92",
        subpage: true,
      },
      {
        title: "Nghĩa vụ của khách hàng",
        route: "/nghia-vu-cua-khach-hang-l.91",
        subpage: true,
      },
      {
        title: "Nghĩa vụ của người bán",
        route: "/nghia-vu-cua-nguoi-ban-l.90",
        subpage: true,
      },
    ];
    return {
      state,
      pages,
    };
  },
};
</script>
